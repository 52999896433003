import React from "react";
import { useDispatch, useSelector } from "react-redux";
import blockChainActions from "../../../store/actions/blockChainActions";

const CertificateButtons = () => {
	const { nfts } = useSelector((state) => state.nft);
	const dispatch = useDispatch();

	return (
		<>
			<div className="col-12 px-4 pl-5 py-2">
				<div className="row">
					{/* <div className="col-6 d-flex justify-content-left align-self-center pl-5 py-2">
            
            <button
              type="button"
              className="btn btn-outline-bgalpha btn-nft-action"
              disabled={! nfts.some((nft) => nft.selected && !nft.claimed) && nfts.some((nft) => nft.claimed && nft.selected)}
              onClick={async () =>  dispatch(blockChainActions.stakeNfts())} 
            >
              STAKE NFT
            </button>
            <span
              className="d-inline-block"
              data-toggle="tooltip"
              title="If you have NFT Authenticity Certificates from Silver Stonks in your digital wallet that are currently not being staked, you can stake them to start earning interest on your silver."
            >
              <i className="fas fa-info-circle p-3 data"></i>
            </span>
          </div>*/}

					<div className="col-md-6 col-12 my-3 d-flex justify-content-left align-self-center pl-5 py-2">
						<button
							type="button"
							className="btn btn-outline-bgalpha btn-nft-action"
							onClick={() =>
								dispatch(blockChainActions.unstakeNfts())
							}
						>
							UNSTAKE NFT
						</button>
						<span
							className="d-inline-block"
							data-toggle="tooltip"
							title="Claim the SSTX tokens you have accumulated through your NFT Authenticity Certificates at any time."
						>
							<i className="fas fa-info-circle p-3 data"></i>
						</span>
					</div>
					<div className="col-md-6 col-12 d-flex justify-content-left align-self-center pl-5 py-2 text-secondary"></div>
					<div className="col-md-6 col-12 my-3 d-flex justify-content-left align-self-center pl-5 py-2">
						<button
							type="button"
							className="btn btn-outline-bgalpha btn-nft-action"
							onClick={() =>
								dispatch(blockChainActions.claimTotalSstx())
							}
						>
							CLAIM TOTAL SSTX
						</button>
						<span
							className="d-inline-block"
							data-toggle="tooltip"
							title="If you wish to unstake your Silver Stonks Authenticity Certificates, you can unstake. Note that this will prevent you from earning interest on your silver until you re-stake the NFT"
						>
							<i className="fas fa-info-circle p-3 data"></i>
						</span>
					</div>

					<div className="col-md-6 col-12 d-flex justify-content-left align-self-center pl-5 py-2 text-secondary">
						You can claim your reward at any time.
					</div>
				</div>
			</div>
		</>
	);
};

export default CertificateButtons;
