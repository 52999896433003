import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../store/actions';
import blockChainActions from '../store/actions/blockChainActions';
import 'react-toastify/dist/ReactToastify.css';
import logoProductInfo from '../static/images/icon-ingot.png';
import bockChainInfo from '../static/images/icon-blockchain.png';
import myNft from '../static/images/my-nft.png';
import ProductInfo from './tabs/ProductInfo';
import BlockChainInfo from './tabs/BlockchainInfo';
import MyNfts from './tabs/mycertificates/MyNfts';
import MyUnStakedNfts from './tabs/mycertificates/MyUnStakedNfts';

const NftTabs = () => {
  const dispatch = useDispatch();
  const { walletAddress } = useSelector((state) => state.auth);
  const { nft } = useSelector((state) => state.nft);
  const intervalTime = 45000;
  
  const [activeTab, setActiveTab] = useState(0);

  const setActiveTabCall = (index) => {
    setActiveTab(index);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(blockChainActions.getUserNfts());
      dispatch(blockChainActions.getUserUnStakedNfts());
    }, intervalTime);
    return () => clearInterval(interval);
  }, []);


  return (
    <>
      <section className="nft-tabs">
        <div className="tabs">
          <div className="tab-list">
            <div className="d-flex justify-content-center">
              <div className={`mx-2 d-flex justify-content-center align-items-center px-3 tab-header ${activeTab === 0 ? 'active-tab' : ''}`} onClick={() => setActiveTabCall(0)}>
                <img className="m-2 my-3" alt="PRODUCT INFO" src={logoProductInfo} height={42}></img>
                PRODUCT INFO
              </div>
              <div className={`mx-2 d-flex justify-content-center align-items-center px-3 tab-header  ${activeTab === 1 ? 'active-tab' : ''}`} onClick={() => setActiveTabCall(1)}>
                <img className="m-2 my-3" alt="BLOCKCHAIN INFO" src={bockChainInfo} height={42}></img>
                BLOCKCHAIN INFO
              </div>
              <div className={`mx-2 d-flex justify-content-center align-items-center px-3 tab-header  ${activeTab === 2 ? 'active-tab' : ''}`} onClick={() => setActiveTabCall(2)}>
                <img className="m-2 my-3" alt="BLOCKCHAIN INFO" src={myNft} height={42}></img>
                MY STAKED CERTIFICATES
              </div>
              <div className={`mx-2 d-flex justify-content-center align-items-center px-3 tab-header  ${activeTab === 3 ? 'active-tab' : ''}`} onClick={() => setActiveTabCall(3)}>
                <img className="m-2 my-3" alt="BLOCKCHAIN INFO" src={myNft} height={42}></img>
                MY UNSTAKED CERTIFICATES
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div className="py-4">
              {activeTab === 0 && <ProductInfo />}
              {activeTab === 1 && <BlockChainInfo />}
              {activeTab === 2 && <MyNfts />}
              {activeTab === 3 && <MyUnStakedNfts />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NftTabs;
