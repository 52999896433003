import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import actions from '../../../store/actions';
import { ReactComponent as EyeIcon } from '../../../static/images/eye.svg';
import blockChainActions from '../../../store/actions/blockChainActions';

const CertificateInfo = ({ nft }) => {
  const dispatch = useDispatch();
  const intervalTime = 5000;

  const loadNft = ($event, nft) => {
    $event.stopPropagation();
    $event.preventDefault();
    dispatch({ type: 'LOAD_NFT', payload: nft });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(blockChainActions.claimable(nft.tokenID));
    }, intervalTime);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div key={nft.tokenID} className={`col-12 nft-item  py-2  `}>
        <label className="control control-checkbox form-check-label d-flex" htmlFor={nft.tokenID}>
          <div className="col-4">
            <input
              className="form-check-input"
              type="checkbox"
              checked={nft.selected}
              id={nft.tokenID}
              onChange={() => dispatch(actions.nftActions.toogleSelectNft(nft.tokenID))}
            />
            <div className="control_indicator"></div>
            <span className="ml-3">{nft.itemName}</span>
          </div>
          <div className="col-4 text-center">
            <span className="data">{nft?.tokenID ?? ''}</span>
          </div>
          <div className="col-4 text-center">
            <span className="data">{((nft?.claimable ?? 0) / 10000000).toFixed(7)}</span>
            <button className="showIcon border-0" onClick={(event) => loadNft(event, nft)}>
              <EyeIcon />
            </button>
          </div>
        </label>
      </div>
    </>
  );
};

export default CertificateInfo;
