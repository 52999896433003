import React from "react";
import cert1 from "../../static/images/imagenes certs_Mesa de trabajo 1.png";
import cert2 from "../../static/images/imagenes certs-02.png";
import cert3 from "../../static/images/imagenes certs-03.png";

const OtherCertificates = () => {
	return (
		<section className="other" id="other-certificates">
			<div className="other-bg">
				<div className="container">
					<div className="other-content mb-5">
						<div className="row">
							<div className="col-12 mt-4">
								<h2>Other Popular Certificates</h2>
							</div>
							<div className="col-lg-4 col-12 d-flex justify-content-center justify-content-lg-end align-items-center my-lg-5">
								<a href="https://silverstonks.com/silver-stonks-silverback-gorilla-statue/" target="_blank">
									<img
										src={cert1}
										alt="SilverBack Statue Certificate"
									/>
								</a>
							</div>
							<div className="col-lg-4 col-12 d-flex justify-content-center align-items-center my-lg-5">
								<a href="https://silverstonks.com/100-gram-silver-bar/" target="_blank">
									<img
										src={cert2}
										alt="SilverBack Statue Certificate"
									/>
								</a>
							</div>
							<div className="col-lg-4 col-12 d-flex justify-content-center justify-content-lg-start align-items-center my-lg-5">
								<a href="https://silverstonks.com/1-kilo-silver-in-a-jar-new/" target="_blank">
									<img
										src={cert3}
										alt="SilverBack Statue Certificate"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OtherCertificates;
