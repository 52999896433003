import React from 'react';
import products from '../../static/images/certificates_summary.jpg';

const History = () => {
  return (
    <section className="history">
      <div className="history-bg">
        <div className="container">
          <div className="history-content mb-5">
            <div className="row ">
              <div className="col-lg-6 d-block d-lg-none">
                <div className="edge-round-bg d-none  d-flex align-items-baseline justify-content-center" id="learnMore">
                  <img alt="History of SilverStonks and products" src={products} />
                </div>
              </div>
              <div className="col-lg-5 my-5">
                <h2>Our Story</h2>
                <p>
                Silver Stonks was founded in an effort to create awareness for a younger demographic to start diversifying their assets 
                as a way to protect themselves and build wealth. The Silver Stonks ecosystem is powered by our native token, SSTX, and 
                makes it easy for investors to diversify into digital and hard assets.
                </p>
                <p>
                While we sell both silver and gold, we have a preference for silver, because of its utility and price accessibility. 
                The silver market is greatly undervalued, making owning silver a great hedge against inflation and long-term investment opportunity.
                </p>
                <p>
                Silver Stonks is leading the way to encourage the average investor to protect and diversify their portfolio. Token holders can use 
                SSTX to buy physical silver In our Silver Shop, earn interest on their physical silver purchases earn up to 12% APY on the SSTX 
                token with staking, as well as other benefits through our Silverback City metaverse and NFT collection.
                </p>
              </div>
              <div className="col-lg-7 d-none d-lg-flex justify-content-center align-items-center pb-5">
                <img src={products} alt="History of SilverStonks and products" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default History;
