import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import actions from '../store/actions';
import LoginModal from './modals/LoginModal';
import VerifyModal from './modals/VerifyModal';
import MetaMaskModal from './modals/MetaMaskModal';
import ChooseHowConnect from './modals/ChooseHowConnect';

const ModalDialogs = () => {
  const [email, setEmail] = useState(window.location.host.includes('localhost') ? 'cmfontana86@gmail.com' : '');
  const [verificationCode, setVerificationCode] = useState('');
  const { modalShow, modalStep, error } = useSelector((state) => state.application);
  const dispatch = useDispatch();

  return (
    <>
    {modalStep != 1 && 
      <Modal
        show={modalShow}
        onHide={() => dispatch(actions.applicationActions.updateModalState(false))}
        backdrop="static"
        keyboard={false}
        id="onboardModal"
        style={{ transform: 'translate(0, 25%)' }}
      >
        
        {/*{modalStep === 1 && <LoginModal email={email} setEmail={setEmail} error={error} />}*/}
        {modalStep === 2 && (
          <VerifyModal setVerificationCode={setVerificationCode} verificationCode={verificationCode} email={email} error={error} />
        )}
        {modalStep === 3 && <ChooseHowConnect />}
        {modalStep === 4 && <MetaMaskModal />}
      </Modal>
    }
    </>
  );
};

export default ModalDialogs;
