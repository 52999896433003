import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import blockChainActions from '../../../store/actions/blockChainActions';

const TotalCount = () => {
  const dispatch = useDispatch();
  const { totalRewards, nfts } = useSelector((state) => state.nft);
  const intervalTime = 5000;

   useEffect(() => {
    const interval = setInterval(() => {
      dispatch(blockChainActions.getTotalRewards());
    }, intervalTime);
    return () => clearInterval(interval);
  }, []);
  
  return (
    <>
      <div className="col-12 px-4 pl-5 py-2">
        <div className="row">
          <div className="col-8 d-flex justify-content-left align-self-center pl-5">
            <h5>TOTAL</h5>
          </div>
          <div className="col-4 d-flex justify-content-center align-self-center font-weight-bold">{((totalRewards ?? 0) / 10000000).toFixed(7)}</div>
        </div>
      </div>
    </>
  );
};

export default TotalCount;
