import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Footer';
import Header from './Header';
import BuySection from './static/BuySection';
import History from './static/History';
import Nft from './static/Nft';
import NftCertificate from './static/NftCertificate';
import Metaverse from './static/Metaverse';
import actions from '../store/actions';
import '../static/scss/main.scss';
import ModalDialogs from './ModalDialogs';
import NftTabs from './NftTabs';
import OtherCertificates from './static/OtherCertificates';

const HomePage = () => {
  localStorage.clear();
  const dispatch = useDispatch();
  const { isAuthenticated, walletAddress } = useSelector((state) => state.auth);

  useEffect(() => {
      if(!isAuthenticated || !walletAddress) {
        dispatch(actions.nftActions.getNft(null, true));
      }
    
  }, [dispatch, isAuthenticated, walletAddress]);

  return (
    <>
      <Header />
      <ToastContainer />
      <ModalDialogs />
      {!isAuthenticated || isAuthenticated && walletAddress && <NftTabs />}
      <OtherCertificates />
      <History />
      <Nft />
      <NftCertificate />
      <Metaverse />
      <BuySection />
      <Footer />
    </>
  );
};

export default HomePage;
