import { BSSCAN_URL, BSSCAN_API_KEY, NFT_STAKING_ADDRESS, NFT_ADDRESS } from '../../config/constants';
import axios from 'axios';

const getNftTransactions = (nftContractAddress) => async (dispatch, getState) => {
  const { nft } = getState().nft;
  const { isAuthenticated, walletAddress } = getState().auth;

  try {
    const { data } = await axios.get(
      `${BSSCAN_URL}api?module=account&action=tokennfttx` +
        `&contractaddress=${NFT_ADDRESS}` +
        // `$address=${NFT_STAKING_ADDRESS}` +aa
        // '&page=1' +
        // '&offset=10' +
        // '&startblock=0' +
        // '&endblock=999999999' +
        '&sort=desc' +
        `&apikey=${BSSCAN_API_KEY}`
    );

    if (data?.message === 'OK') {
      localStorage.setItem('isWalletConnected', true);
      const transactions = data?.result;
      const transactionShowed = transactions.find((transaction) => transaction.tokenID === nft.tokenID) ?? transactions[0];
      if (transactionShowed) {
        dispatch({
          type: 'LOAD_NFT',
          payload: { ...nft, ...transactionShowed },
        });
        dispatch({
          type: 'LOAD_NFT_TRANSACTION',
          payload: transactionShowed
        })
      }

      if (data.result?.length > 0) {
        dispatch({
          type: 'SET_USER_NFT_TRANSACTIONS',
          payload: data.result,
        });
      }
      // }
    }

    return data;
  } catch (err) {
    console.error('ERRORO', err);
  }
};

const bsscanActions = {
  getNftTransactions,
};

export default bsscanActions;
