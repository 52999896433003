import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CertificateInfo from "./CertificateInfo";
import TotalCount from "./TotalCount";
import CertificateButtons from "./CertificatesButtons";
import blockChainActions from "../../../store/actions/blockChainActions";

const MyNfts = () => {
	const dispatch = useDispatch();
	const { nfts } = useSelector((state) => state.nft);
	const { totalEarned } = useSelector((state) => state.nft);
	const intervalTime = 5000;

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(blockChainActions.getTotalEarned());
		}, intervalTime);
		return () => clearInterval(interval);
	}, []);

	return (
		<section className="tab my-nfts">
			<div className="container mb-3">
				<div className="row">
					{nfts.length < 1 && (
						<div className="col-12 d-flex justify-content-center">
							There are no{" "}
							<span className="data">&nbsp;NFTs&nbsp;</span>{" "}
							associated to your wallet
						</div>
					)}
					{nfts.length > 0 && (
						<>
							<div className="col-lg-9 col-12 mt-5">
								<div className="row">
									<div className="col-4 d-flex align-self-center justify-content-center">
										PRODUCT
									</div>
									<div className="col-4 d-flex align-self-center justify-content-center">
										TOKEN ID
									</div>
									<div className="col-4 d-flex align-self-center justify-content-center text-center">
										CLAIMABLE
										<br /> SSTX AMOUNT
									</div>
									{Object.values(nfts).map((nft, index) => (
										<CertificateInfo
											key={"certificate-info-" + index}
											nft={nft}
										/>
									))}
									<TotalCount />
								</div>
							</div>
							<div className="col-lg-3 col-12 text-center my-5">
								<div className="px-3 py-2 totalEarner">
									<h4>TOTAL SSTX</h4>
									<h4>EARNED</h4>
									{((totalEarned ?? 0) / 10000000).toFixed(7)}
								</div>
							</div>
							<CertificateButtons />
						</>
					)}
				</div>
			</div>
		</section>
	);
};

export default MyNfts;
