import React from 'react';
import { useSelector } from 'react-redux';

const BlockChainInfo = () => {
  const { nft } = useSelector((state) => state.nft);
  const { nftTransaction, nftTransactions } = useSelector((state) => state.nftTransaction);

  return (
    <section className="tab product-info blockchange-info">
      <div className="container mb-3">
        <div className="row">
          <div className="col-12 p-1 product-info-data d-flex justify-content-between">
            <span className="label px-3 col-3"> Transaction Hash: </span>
            <span className="data pr-0 col-9">
              {nft?.hash ?? nftTransaction?.hash ?? (nftTransactions?.length > 0 ? nftTransactions[0].hash : '')}
            </span>
          </div>
          <div className="col-12 p-1 product-info-data d-flex justify-content-between">
            <span className="label col-3 px-3"> Block Hash: </span>
            <span className="data col-9 pr-0">
              {nft?.blockHash ?? nftTransaction?.blockHash ?? (nftTransactions?.length > 0 ? nftTransactions[0].blockHash : '')}
            </span>
          </div>
          <div className="col-12 p-1 product-info-data d-flex justify-content-between">
            <span className="label col-3 px-3"> Transaction time: </span>
            <span className="data col-9 pr-0">
              {nft?.timeStamp > 0
                ? new Date(parseInt(nft.timeStamp, 10)).toLocaleTimeString()
                : nftTransaction?.timeStamp > 0
                ? new Date(parseInt(nftTransaction.timeStamp, 10)).toLocaleTimeString()
                : nftTransactions?.length > 0
                ? new Date(parseInt(nftTransactions[0].timeStamp, 10)).toLocaleTimeString()
                : ''}
            </span>
          </div>
          <div className="col-12 p-1 product-info-data d-flex justify-content-between">
            <span className="label col-3 px-3"> Transaction to: </span>
            <span className="data col-9 pr-0">{nft.to ?? nftTransaction?.to ?? (nftTransactions?.length > 0 ? nftTransactions[0].to : '')}</span>
          </div>
          <div className="col-12 p-1 product-info-data d-flex justify-content-between">
            <span className="label col-3 px-3"> Gas limit: </span>
            <span className="data col-9 pr-0">{nft.gas ?? nftTransaction?.gas ?? (nftTransactions?.length > 0 ? nftTransactions[0].gas : '')}</span>
          </div>
          <div className="col-12 p-1 product-info-data d-flex justify-content-between">
            <span className="label col-3 px-3"> Gas price: </span>
            <span className="data col-9 pr-0">
              {nft.gasPrice ?? nftTransaction?.gasPrice ?? (nftTransactions?.length > 0 ? nftTransactions[0].gasPrice : '')}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlockChainInfo;
