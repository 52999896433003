import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import actions from ".";
import Web3 from "web3";
import {
  NFT_ABI,
  NFT_ADDRESS,
  NFT_STAKING_ABI,
  NFT_STAKING_ADDRESS,
} from "../../config/constants";
import { toast } from "react-toastify";
import { LOAD_BLOCKCHAIN } from "../types";
import bsscanActions from "./bsscanAPIActions";
const { ethereum } = window;
const molarisRPCUrl = "wss://ws-nd-524-683-647.p2pify.com/d3ab8244fe56f720a29f4a79384b15ef";

 // "wss://ws-nd-524-683-647.p2pify.com/d3ab8244fe56f720a29f4a79384b15ef";
let web3;
let isMetamask = false;
let connector;


const connectTowalletConnect = async (dispatch) => {
  connector = new WalletConnect({
    bridge: "https://bridge.walletconnect.org", // Required
    qrcodeModal: QRCodeModal,
  });
  if (!connector.connected) {
    connector.createSession({ chainId: 56 });
  }
  connector.on("connect", (error, payload) => {
    if (error) {
      console.error(error);
    } else if (
      payload.params.length > 0 &&
      payload.params[0].accounts.length > 0
    ) {
      dispatch({
        type: "LOAD_WALLET_ADDRESS",
        payload: payload.params[0].accounts[0],
      });

      web3 = new Web3(molarisRPCUrl);

      const Web3Modal = window.Web3Modal.default;

      const providerOptions = {};

      /*let web3Provider = new Web3Modal({
        network: 'mainnet',
        cacheProvider: false,
        providerOptions,
        disableInjectedProvider: false,
      }).connect();*/

      const stakingContract = new web3.eth.Contract(
        NFT_STAKING_ABI,
        NFT_STAKING_ADDRESS
      );

      const nftContract = new web3.eth.Contract(NFT_ABI, NFT_ADDRESS);

      // TODO actualizar el token en BE
      /*
            axios.get(`${APIURL}/getUserByWalletAddress/${web3Account}`)
              .then(({ data }) => {
                  dispatch({
                      type: "LOAD_USER",
                      payload: {
                          user: data.data[0],
                          token: user.token,
                      },
                  });

              });
              */

      dispatch({
        type: LOAD_BLOCKCHAIN,
        payload: { web3, stakingContract, nftContract },
      });

      dispatch(actions.applicationActions.updateModalState(false));
      dispatch(getUserNfts());
      dispatch(bsscanActions.getNftTransactions());
      toast.success("You are now logged in!");
    }

    console.log(payload);
  });
};

const connectToMetaMask = async (dispatch) => {
  if (ethereum) {
    ethereum.request({ method: "eth_requestAccounts" }).then(
      (response) => {
        dispatch({
          type: "LOAD_WALLET_ADDRESS",
          payload: response[0],
        });

        web3 = new Web3(window.web3.currentProvider);
        const stakingContract = new web3.eth.Contract(
          NFT_STAKING_ABI,
          NFT_STAKING_ADDRESS
        );

        const nftContract = new web3.eth.Contract(NFT_ABI, NFT_ADDRESS);
        isMetamask = true;

        // TODO actualizar el token en BE
        /*
              axios.get(`${APIURL}/getUserByWalletAddress/${web3Account}`)
                .then(({ data }) => {
                    dispatch({
                        type: "LOAD_USER",
                        payload: {
                            user: data.data[0],
                            token: user.token,
                        },
                    });
  
                });
                */

        dispatch({
          type: LOAD_BLOCKCHAIN,
          payload: { web3, stakingContract, nftContract },
        });

        dispatch(actions.applicationActions.updateModalState(false));
        dispatch(getUserNfts());
        dispatch(bsscanActions.getNftTransactions());
        toast.success("You are now logged in!");
      },
      (error) => {
        //Error Message
      }
    );
  } else {
    //Error Message to install metamask
    return false;
  }
};

/**
 * 1. Request UserDetails from blockchain
 * 1.1 Avaible NFT from userWallet
 *     - Array<String> : UUID
 *
 *
 * @returns All userData
 */
const getUserNfts = () => async (dispatch, getState) => {
  const { isAuthenticated, walletAddress } = getState().auth;
  const { nftContract, stakingContract } = getState().blockChain || {};
  const { nfts } = getState().nft || [];

  if (isAuthenticated && walletAddress && nftContract && stakingContract) {
    try {
      if (!isMetamask) {
        if (!stakingContract.currentProvider.connected) {
          if (stakingContract?.currentProvider?.enable) {
            await stakingContract.currentProvider.enable();
          } else {
            await stakingContract.currentProvider.connect();
          }
        }

        if (!nftContract.currentProvider.connected) {
          if (nftContract?.currentProvider?.enable) {
            await nftContract.currentProvider.enable();
          } else {
            await nftContract.currentProvider.connect();
          }
        }
      }

      const nftsList = [];
      /* ARRAY<NUMBER> PARA TENER LOS NFT DEL USUARIO */
      const tokensId = await stakingContract.methods
        .depositsOf(
          window.location.host.includes("localhost")
            ? walletAddress
            : walletAddress
        )
        .call();
      for (const tokenID of tokensId) {
        const nftId = await stakingContract.methods
          .tokenDetails(tokenID)
          .call();

        const data = await nftContract.methods
          .getProductCertificateDetail(nftId)
          .call();
          
        const nft = { sku: data[2], tokenID, nftId, selected: !!nfts.find(nft => nft.tokenID === tokenID)?.selected };
        console.log("Token found", nft);
        nftsList.push(nft);
      }

      dispatch({
        type: "SET_USER_NFTS",
        payload: nftsList,
      });
      dispatch(actions.nftActions.getNfts());
      dispatch(getTotalEarned());
      dispatch(getTotalRewards());
      return nftsList;
    } catch (err) {
      console.error("ERRORO", err);
    }
  }
};

const getUserUnStakedNfts = () => async (dispatch, getState) => {
  const { isAuthenticated, walletAddress } = getState().auth;
  const { nftContract, stakingContract } = getState().blockChain || {};

  if (isAuthenticated && walletAddress && nftContract && stakingContract) {
    try {
      if (!isMetamask) {
        if (!stakingContract.currentProvider.connected) {
          if (stakingContract?.currentProvider?.enable) {
            await stakingContract.currentProvider.enable();
          } else {
            await stakingContract.currentProvider.connect();
          }
        }

        if (!nftContract.currentProvider.connected) {
          if (nftContract?.currentProvider?.enable) {
            await nftContract.currentProvider.enable();
          } else {
            await nftContract.currentProvider.connect();
          }
        }
      }

      const nfts = [];
      //const balanceOf = "3";
      const balanceOf = await nftContract.methods
        .balanceOf(walletAddress)
        .call();

      let promise = [];
      for (let i = 0; i < Number(balanceOf); i++) {
        promise.push(
          nftContract.methods.tokenOfOwnerByIndex(walletAddress, i).call()
        );
      }
      Promise.all(promise).then(
        async (res) => {
          const tokensId = res;

          for (const tokenID of tokensId) {
            const nftId = await stakingContract.methods
              .tokenDetails(tokenID)
              .call();
            const data = await nftContract.methods
              .getProductCertificateDetail(nftId)
              .call();
            const nft = { sku: data[2], tokenID, nftId, selected: false };
            nfts.push(nft);
          }

          dispatch({
            type: "SET_USER_NFTS",
            payload: nfts,
          });
          dispatch(actions.nftActions.getNfts());
          dispatch(getTotalEarned());
          dispatch(getTotalRewards());
          return nfts;
        },
        (error) => {}
      );
    } catch (err) {
      console.error("ERRORO", err);
    }
  }
};

const getTotalRewards = () => async (dispatch, getState) => {
  const { isAuthenticated, walletAddress } = getState().auth;
  const { nfts } = getState().nft;
  const { stakingContract } = getState().blockChain || {};

  try {
    if (!stakingContract.currentProvider.connected) {
      if (stakingContract?.currentProvider?.enable) {
        await stakingContract.currentProvider.enable();
      } else {
        await stakingContract.currentProvider.connect();
      }
    }
    if (isAuthenticated && walletAddress) {
      const totalRewards = await stakingContract.methods
        .calculateRewards(
          window.location.host.includes("localhost")
            ? walletAddress
            : walletAddress,
          nfts.map((nft) => nft.tokenID)
        )
        .call();
      
      dispatch({
        type: "SET_TOTAL_REWARDS",
        payload:
          totalRewards?.length > 0
            ? totalRewards
                .map((reward) => parseInt(reward, 10))
                .reduce((pre, curr) => pre + curr)
            : 0,
      });
    }
  } catch (e) {
    console.error(e);
  }
};
const checkStakingApproved = () => async (dispatch, getState) => {
  const { isAuthenticated, walletAddress } = getState().auth;
  const { nftContract } = getState().blockChain || {};

  if (isAuthenticated && walletAddress && nftContract) {
    if (!isMetamask) {
      if (!nftContract.currentProvider.connected) {
        if (nftContract?.currentProvider?.enable) {
          await nftContract.currentProvider.enable();
        } else {
          await nftContract.currentProvider.connect();
        }
      }
    }

    const isApprovedApprovedAll = await nftContract.methods
      .isApprovedForAll(walletAddress, NFT_STAKING_ADDRESS)
      .call();
    dispatch({
      type: "isApprovedApprovedAll",
      payload: isApprovedApprovedAll,
    });
  }
};

const getTotalEarned = () => async (dispatch, getState) => {
  const { isAuthenticated, walletAddress } = getState().auth;
  const { stakingContract } = getState().blockChain || {};

  if (isAuthenticated && walletAddress) {
    try {
      if (!stakingContract.currentProvider.connected) {
        if (stakingContract?.currentProvider?.enable) {
          await stakingContract.currentProvider.enable();
        } else {
          await stakingContract.currentProvider.connect();
        }
      }

      const totalEarned = await stakingContract.methods
        .rewardCollected(
          window.location.host.includes("localhost")
            ? walletAddress
            : walletAddress
        )
        .call();
      dispatch({
        type: "SET_TOTAL_EARNED",
        payload: totalEarned,
      });
    } catch (e) {
      console.error(e);
    }
  }
};

const stakeNfts = () => async (dispatch, getState) => {
  const { isAuthenticated, walletAddress } = getState().auth;
  const { stakingContract } = getState().blockChain;
  const { nfts } = getState().nft;

  if (isAuthenticated && walletAddress) {
    try {
      if (!stakingContract.currentProvider.connected) {
        if (stakingContract?.currentProvider?.enable) {
          await stakingContract.currentProvider.enable();
        } else {
          await stakingContract.currentProvider.connect();
        }
      }
      const selectedNfts = nfts
        .filter((nft) => nft.selected)
        .map((nft) => nft.tokenID);
      if (isMetamask) {
        const result = await stakingContract.methods
          .deposit(selectedNfts)
          .send({ from: walletAddress });
        dispatch(getUserUnStakedNfts());
      } else {
        const data = stakingContract.methods.deposit(selectedNfts).encodeABI();
        const tx = { from: walletAddress, to: NFT_STAKING_ADDRESS, data };
        connector
          .sendTransaction(tx)
          .then((result) => {
            dispatch(getUserUnStakedNfts());
          })
          .catch((error) => {
            dispatch(getUserUnStakedNfts());
          });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

const unstakeNfts = () => async (dispatch, getState) => {
  const { isAuthenticated, walletAddress } = getState().auth;
  const { stakingContract } = getState().blockChain;
  const { nfts } = getState().nft;

  if (isAuthenticated && walletAddress) {
    try {
      if (!isMetamask) {
        if (!stakingContract.currentProvider.connected) {
          if (stakingContract?.currentProvider?.enable) {
            await stakingContract.currentProvider.enable();
          } else {
            await stakingContract.currentProvider.connect();
          }
        }
      }
      const selectedNfts = nfts
        .filter((nft) => nft.selected)
        .map((nft) => nft.tokenID);
      if (isMetamask) {
        const result = await stakingContract.methods
          .withdraw(selectedNfts)
          .send({ from: walletAddress });
        // const selectedNfts = nfts.filter((nft) => nft.selected);
        //const result = await stakingContract.methods.withdraw(
        // selectedNfts.map(nft => nft.claimable).reduce((prev, curr) => prev += curr),
        // selectedNfts.map(nft => nft.tokenID)
        //).call();
        dispatch(getUserNfts());
      } else {
        const data = stakingContract.methods.withdraw(selectedNfts).encodeABI();
        const tx = { from: walletAddress, to: NFT_STAKING_ADDRESS, data };
        connector
          .sendTransaction(tx)
          .then((result) => {
            dispatch(getUserNfts());
          })
          .catch((error) => {
            dispatch(getUserNfts());
          });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

const claimTotalSstx = () => async (dispatch, getState) => {
  const { isAuthenticated, walletAddress } = getState().auth;
  const { stakingContract } = getState().blockChain;
  const { nfts } = getState().nft;

  if (isAuthenticated && walletAddress) {
    try {
      if (!isMetamask) {
        if (!stakingContract.currentProvider.connected) {
          if (stakingContract?.currentProvider?.enable) {
            await stakingContract.currentProvider.enable();
          } else {
            await stakingContract.currentProvider.connect();
          }
        }
      }

      const selectedNfts = nfts
        .filter((nft) => nft.selected && !nft.claimed)
        .map((nft) => nft.tokenID);
      if (isMetamask) {
        const result = await stakingContract.methods
          .claimRewards(selectedNfts)
          .send({ from: walletAddress });
        //const selectedNfts = nfts.filter((nft) => nft.selected && !nft.claimed).map(nft => nft.tokenID);
        //const result = await stakingContract.methods.claimRewards(selectedNfts).call();
        dispatch(getUserNfts());
      } else {
        const data = stakingContract.methods
          .claimRewards(selectedNfts)
          .encodeABI();
        const tx = { from: walletAddress, to: NFT_STAKING_ADDRESS, data };
        connector
          .sendTransaction(tx)
          .then((result) => {
            dispatch(getUserNfts());
          })
          .catch((error) => {
            dispatch(getUserNfts());
          });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

const approveStaking = () => async (dispatch, getState) => {
  const { isAuthenticated, walletAddress } = getState().auth;
  const { nftContract } = getState().blockChain || {};

  if (!isMetamask) {
    if (!nftContract.currentProvider.connected) {
      if (nftContract?.currentProvider?.enable) {
        await nftContract.currentProvider.enable();
      } else {
        await nftContract.currentProvider.connect();
      }
    }
  }

  if (isAuthenticated && walletAddress && nftContract && isMetamask) {
    nftContract.methods
      .setApprovalForAll(NFT_STAKING_ADDRESS, true)
      .send({ from: walletAddress })
      .then(
        (_) => {
          dispatch({
            type: "isApprovedApprovedAll",
            payload: true,
          });
        },
        (error) => {}
      );
  } else {
    const data = nftContract.methods
      .setApprovalForAll(NFT_STAKING_ADDRESS, true)
      .encodeABI();
    const tx = { from: walletAddress, to: NFT_ADDRESS, data };
    connector
      .sendTransaction(tx)
      .then((result) => {
        dispatch({ type: "isApprovedApprovedAll", payload: true });
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

const claimable = (tokenID) => async (dispatch, getState) => {
  const { isAuthenticated, walletAddress } = getState().auth;
  const { stakingContract } = getState().blockChain;

  if (isAuthenticated && walletAddress) {
    try {
      if (!stakingContract.currentProvider.connected) {
        if (stakingContract?.currentProvider?.enable) {
          await stakingContract.currentProvider.enable();
        } else {
          await stakingContract.currentProvider.connect();
        }
      }
      const claimableString = await stakingContract.methods
        .calculateReward(
          window.location.host.includes("localhost")
            ? walletAddress
            : walletAddress,
          tokenID
        )
        .call();

      let claimable = 0;
      if (!!claimableString) {
        if (
          typeof claimableString === "string" &&
          claimableString?.length > 0
        ) {
          claimable = parseInt(claimableString, 10);
        } else if (typeof claimableString === "number") {
          claimable = claimableString;
        }
      }

      dispatch({
        type: "UPDATE_CLAIMABLE",
        payload: { tokenID, claimable:claimable.toFixed(2) },
      });
    } catch (e) {
      console.error(e);
    }
  } else {
    return 0;
  }
};

const blockChainActions = {
  getUserNfts,
  connectToMetaMask,
  connectTowalletConnect,
  getTotalEarned,
  getTotalRewards,
  stakeNfts,
  claimTotalSstx,
  claimable,
  getUserUnStakedNfts,
  unstakeNfts,
  checkStakingApproved,
  approveStaking,
};

export default blockChainActions;
