const config = require("./sstxconfig.json");

// Application constants + thin wrapper over the sstxconfig.json file
// Add application-wide constants here to prevent misconfiguration

export const APIURL = config.ENV === "prod" ? config.API_URL_PROD : config.API_URL_LOCAL;
export const ACTIVENET = config.ACTIVENET;
export const THRESHOLD_LEVEL_1 = config.THRESHOLD_LEVEL_1;
export const UNIT_LEVEL = config.UNIT_LEVEL;
export const REFRESH_DELAY = 60000;


// NFT STAKING
export const NFT_STAKING_ADDRESS = ACTIVENET === 'main' ?  config.NFT_STAKING_ADDRESS_MAINNET: config.NFT_STAKING_ADDRESS_MAINNET;
export const NFT_STAKING_ABI = ACTIVENET === 'main' ?  config.NFT_STAKING_ABI_MAINNET: config.NFT_STAKING_ABI_MAINNET;

 // NFT
export const NFT_ADDRESS = ACTIVENET === 'main' ?   config.NFT_ADDRESS_MAINNET: config.NFT_ADDRESS_MAINNET;
export const NFT_ABI = ACTIVENET === 'main' ?  config.NFT_ABI_MAINNET : config.NFT_ABI_MAINNET;



// BSSCAN
// export const BSSCAN_URL = ACTIVENET === "main" ? config.BSSCAN_URL_PROD : config.BSSCAN_URL_TESNET;
export const BSSCAN_URL =  config.BSSCAN_URL_PROD;  
export const BSSCAN_API_KEY = config.BSSCAN_API_KEY;

