const initialState = {
  nft: {},
  totalEarned: 0,
  totalRewards: 0,
  nfts: [],
  isAllApproved: false,
};

export default function nftReducer(state = initialState, action) {
  switch (action.type) {
    case "LOAD_NFT":
      return {
        ...state,
        nft: action.payload,
      };
    case "isApprovedApprovedAll":
      return {
        ...state,
        isAllApproved: action.payload,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        nfts: [],
        totalEarned: 0,
        totalRewards: 0,
      };
    case "CLAIM_NFT":
      return {
        ...state,
      };
    case "SET_USER_NFTS":
      return {
        ...state,
        nfts: action.payload,
      };
    case "TOOGLE_NFT":
      return {
        ...state,
        nfts: state.nfts.map((nft) => {
          if (nft.tokenID === action.payload) {
            nft.selected = !nft.selected;
          }
          return nft;
        }),
      };

    case "SET_TOTAL_EARNED":
      return {
        ...state,
        totalEarned: action.payload,
      };

    case "SET_TOTAL_REWARDS":
      return {
        ...state,
        totalRewards: action.payload,
      };

    case "UPDATE_CLAIMABLE":
      return {
        ...state,
        nfts: state.nfts.map((nft) =>
          nft.tokenID === action.payload.tokenID
            ? {
                ...nft,
                claimed: action.payload.claimable < 1,
                claimable: action.payload.claimable,
              }
            : nft
        ),
        nft:
          state.nft.tokenID === action.payload.tokenID
            ? {
                ...state.nft,
                claimed: action.payload.claimable < 1,
                claimable: action.payload.claimable,
              }
            : state.nft,
      };

    default:
      return state;
  }
}
