const initialState = {
  nftTransaction: {},
  nftTransactions: [],
};

export default function nftTransactionReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOAD_NFT_TRANSACTION':
      return {
        ...state,
        nftTransaction: action.payload,
      };
    case 'LOGOUT_USER':
      return {
        ...state,
        ...initialState,
      };
    case 'SET_USER_NFT_TRANSACTIONS':
      return {
        ...state,
        nftTransactions: action.payload,
      };
    case 'LOAD_NFT':
      return {
        ...state,
        nftTransaction:
          state.nftTransactions?.find((transaction) => transaction.tokenID === action.payload.tokenID) ?? state.nftTransactions[0] ?? {},
      };
    default:
      return state;
  }
}
